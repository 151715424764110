<template>
  <el-row :gutter="40">
    <el-col
      v-for="(item, index) of items"
      :key="index"
      :xs="12"
      :sm="12"
      :lg="6"
      class="flex items-stretch flex-row"
    >
      <div
        v-loading="loading"
        :class="$store.getters.getCurrentModule.submodule === item.label.toLowerCase() ? 'opacity-100 border-white bg-gray-900' : 'border-gray-700'"
        class="opacity-50 hover:opacity-75 w-full cursor-pointer border bg-white-500 rounded-lg p-4 px-16 pl-8 mb-8 flex flex-row"
        @click="toPage(item)"
      >
        <div>
          <i :class="`ri-${item.icon}-line ri-4x text-blue-700`" />
        </div>
        <div class="flex flex-col justify-center ml-8">
          <div class="text-gray-600">
            {{ item.label }}
          </div>
          <div class="text-3xl font-bold">
            {{ item.count }}
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      items: [
        {
          label: 'Users',
          count: 0,
          icon: 'user',
          to: '/dashboard/data/users'
        },
        {
          label: 'Groups',
          count: 0,
          icon: 'group',
          to: '/dashboard/data/groups'
        },
        {
          label: 'Cameras',
          count: 0,
          icon: 'vidicon',
          to: '/dashboard/data/cameras'
        },
        {
          label: 'Clusters',
          count: 0,
          icon: 'pin-distance',
          to: '/dashboard/data/clusters'
        }
      ]
    }
  },
  mounted() {
    // console.log(this.$store.state);
    this.renderData();
  },
  methods: {
    toPage(item) {
      this.$router.push(item.to);
    },
    async fetchData() {
      return new Promise(async (resolve) => {
        const params = {
          page: 1,
          size: 0
        };
        const users = await this.$request.get('records/users', { params }).then(response => response.data.results );
        const groups = await this.$request.get('records/groups', { params }).then(response => response.data.results);
        const cameras = await this.$request.get('records/cameras', { params }).then(response => response.data.results);
        const clusters = await this.$request.get('records/clusters', { params }).then(response => response.data.results);
        resolve({ users, groups, cameras, clusters });
      })
    },
    async renderData() {
      const data = await this.fetchData();
      this.loading = false;
      this.items[0].count = data.users;
      this.items[1].count = data.groups;
      this.items[2].count = data.cameras;
      this.items[3].count = data.clusters;
    },
    handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type)
    }
  }
}
</script>
