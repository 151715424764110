<template>
  <el-main>
    <PanelGroup
      :key="$store.getters.getKey.panel"
      class="mb-8"
    />
    <!-- <pre> -->
    <!-- {{ $store.getters.getCurrentAuthUser }} -->
    <!-- </pre> -->
    <router-view />
  </el-main>
</template>

<script>

// import UsersPage from './Users/Users.page';
// import CamerasPage from './Cameras/Cameras.page';
// import GroupsPage from './Groups/Groups.page';

import PanelGroup from './PanelGroup';

export default {
  components: {
    PanelGroup
    // UsersPage,
    // GroupsPage,
    // CamerasPage,
  },
  data() {
    return {
      activeTab: 'users',
    }
  },
  mounted() {
  },
  methods: {
    
  }
}
</script>

<style>

</style>